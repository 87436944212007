/* @src/components/Modal.module.css */

.darkBG {
  background-color: rgb(0 38 104);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  opacity: 0.3;
}

.darkBGLogout {
  background-color: rgb(0 38 104);
  width: 100vw;
  height: 100vh;
  z-index: 50;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  opacity: 0.3;
}

.confirmDeleteBG {
  background-color: rgb(0 38 104);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  opacity: 0.3;
}
.productViewBG {
  background-color: rgb(0 38 104);
  width: 100vw;
  height: 100vh;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: fixed;
  opacity: 0.3;
}

.centered {
  position: fixed;
  z-index: 50;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}
.centeredMobile {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.productCentered {
  position: fixed;
  z-index: 10;
  top: 50%;
  left: 55%;
  transform: translate(-50%, -50%);
}

.modal {
  width: 400px;
  height: 150px;
  background: white;
  border-radius: 10px;
  padding-top: 4px;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.modalAction {
  width: 400px;
  height: 300px;
  background: white;
  border-radius: 10px;
  padding-top: 4px;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.productModal {
  width: 50vw;
  height: 90vh;
  background: white;
  border-radius: 10px;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.withdrawModal {
  width: 45vw;
  height: 90vh;
  background: white;
  border-radius: 10px;
  overflow: auto;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.productViewModal {
  width: 80vw;
  height: 90vh;
  background: white;
  border-radius: 10px;
  overflow: auto;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}
.notificationViewModal {
  width: 50vw;
  height: 90vh;
  background: white;
  border-radius: 10px;
  overflow: auto;
  color: white;
  z-index: 10;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
}

.modalHeader {
  height: 50px;
  background: white;
  overflow: hidden;
  border-top-left-radius: 16px;
  border-top-right-radius: 16px;
}

.heading {
  margin: 0;
  padding: 10px;
  color: #2c3e50;
  font-weight: 500;
  font-size: 1rem;
  text-align: center;
}

.modalContent {
  padding: 10px;
  font-size: 0.875rem;
  color: #2c3e50;
  text-align: center;
  height: 90%;
}

.modalActions {
  position: absolute;
  bottom: 2px;
  margin-bottom: 10px;
  width: 100%;
}

.actionsContainer {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.closeBtn {
  cursor: pointer;
  font-weight: 500;
  padding: 4px 8px;
  border-radius: 8px;
  border: none;
  font-size: 18px;
  color: #2c3e50;
  background: white;
  transition: all 0.25s ease;
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.06);
  position: absolute;
  right: 0;
  top: 0;
  align-self: flex-end;
  margin-top: -7px;
  margin-right: -7px;
}

.closeBtn:hover {
  box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  transform: translate(-4px, 4px);
}

.deleteBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #ff3e4e;
  transition: all 0.25s ease;
  border-radius: 5px;
}
.deactivateBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  transition: all 0.25s ease;
  border-radius: 5px;
}

.deleteBtn:hover {
  box-shadow: 0 10px 20px -10px rgba(255, 62, 78, 0.6);
  transform: translateY(-5px);
  background: #ff3e4e;
}

.cancelBtn {
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  font-size: 0.8rem;
  border: 1px solid whitesmoke;
  color: #2c3e50;
  background: #fcfcfc;
  transition: all 0.25s ease;
  border-radius: 5px;
}

.cancelBtn:hover {
  box-shadow: none;
  transform: none;
  background: whitesmoke;
}
.primaryBtn {
  margin: 20px 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 13px 25px;
  font-size: 0.8rem;
  border: none;
  color: white;
  background: #185adb;
  transition: all 0.25s ease;
}

.primaryBtn:hover {
  transform: translateY(-5px);
  box-shadow: 0 10px 20px -10px rgba(24, 90, 219, 0.6);
}

.updateBtn {
  width: 120px;
  height: 40px;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 500;
  padding: 11px 28px;
  font-size: 0.8rem;
  border: none;
  color: #fff;
  background: #0b3954;
  transition: all 0.25s ease;
}

.updateBtn:hover {
  box-shadow: 0 10px 20px -10px #052f47;
  transform: translateY(-5px);
  background: #0b3954;
}

@media only screen and (max-width: 600px) {
  .centered {
    position: fixed;
    z-index: 10;
    top: 40%;
    left: 70%;
    transform: translate(-50%, -50%);
  }
  .centeredMobile {
    position: fixed;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .modal {
    width: 300px;
    height: 150px;
    background: white;
    color: white;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
    border-radius: 15px;
  }
  .modalContent {
    padding: 10px;
    font-size: 0.875rem;
    color: #2c3e50;
    text-align: center;
    height: 85%;
  }

  .darkBG {
    background-color: rgba(0, 0, 0, 0.2);
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
  .darkBGLogout {
    background-color: rgb(0 38 104);
    width: 1000px;
    height: 100vh;
    z-index: 10;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
    opacity: 0.3;
  }
  .confirmDeleteBG {
    background-color: rgb(0 38 104);
    width: 100vw;
    height: 100vh;
    position: fixed;
  }
  .productModal {
    width: 100vw;
    height: 100vh;
    background: white;
    border-radius: 10px;
    overflow: auto;
    color: white;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  .withdrawModal {
    width: 100vw;
    height: 80vh;
    background: white;
    border-radius: 10px;
    overflow: auto;
    color: white;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
  .productCentered {
    position: fixed;
    z-index: 10;
    top: 52%;
    height: 80%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
@media only screen and (max-width: 900px) {
  .productModal {
    width: 90vw;
    height: 80vh;
    background: white;
    border-radius: 10px;
    overflow: auto;
    color: white;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1440px) {
  .withdrawModal {
    width: 60vw;
    height: 90vh;
    background: white;
    border-radius: 10px;
    overflow: auto;
    color: white;
    z-index: 10;
    box-shadow: 0 5px 20px 0 rgba(0, 0, 0, 0.04);
  }
}
