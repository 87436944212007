nav {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  border-bottom: 1px solid gray;
  height: 45px;
}

ul,
li {
  list-style: none;
  width: 100%;
  justify-content: space-evenly;
  height: 100%;
}

ul {
  display: flex;
  width: 100%;
}

li {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
  min-width: 0;
  position: relative;
  user-select: none;
}
