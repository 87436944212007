.main-container::-webkit-scrollbar {
  display: none;
}

.instructions {
  font-size: 0.75rem;
  color: #e60b0b;
  position: relative;
}

.instructions > svg {
  margin-right: 0.25rem;
}

.offscreen {
  position: absolute;
  left: -9999px;
}
