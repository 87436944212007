@tailwind base;
@tailwind components;
@tailwind utilities;

/* Poppins */
@font-face {
  font-family: PoppinsRegular;
  src: url('assets/fonts/Poppins-Regular.ttf') format('truetype');
}
@font-face {
  font-family: PoppinsMedium;
  src: url('assets/fonts/Poppins-Medium.ttf') format('truetype');
}
@font-face {
  font-family: PoppinsSemibold;
  src: url('assets/fonts/Poppins-SemiBold.ttf') format('truetype');
}
@font-face {
  font-family: PoppinsBold;
  src: url('assets/fonts/Poppins-Bold.ttf') format('truetype');
}

@layer base {
  .transition-hover {
    @apply transition ease-in-out delay-150 hover:-translate-y-1 hover:scale-100 duration-300;
  }
}

.td-class {
  @apply px-4 py-3 text-black font-medium bg-gray-200 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg;
}
.td-transClass {
  @apply px-3 py-2 text-black font-medium bg-gray-200 first:rounded-tl-lg first:rounded-bl-lg last:rounded-tr-lg last:rounded-br-lg text-xs;
}

body {
  font-size: 0.875rem;
  color: #252528;
  font-family: PoppinsRegular;
}

::-webkit-scrollbar {
  width: 0.45em;
  margin: 10px;
  background-color: #825a3f7d;
  /* background-color: #e6f8fd; */
}

::-webkit-scrollbar-track {
  background-color: #825a3f7d;
  /* background-color: #e6f8fd; */
  margin-block: 0.5rem;
}

::-webkit-scrollbar-thumb {
  border-radius: 100vw;
  background-color: #95867c;
  /* background-color: #00b4ea; */
}

::-webkit-scrollbar-thumb:hover {
  background-color: #825a3f;
  /* background-color: #076987; */
  width: 0.45em;
}

.chat-container {
  box-sizing: border-box;
  scroll-behavior: smooth;
  color: white;
  background: rgb(11, 11, 11);
}

/* All users */
.all-users {
  display: flex;
  margin-bottom: 1rem;
  color: white;
}

.single-user {
  background: rgb(0, 213, 255);
  cursor: pointer;
  padding: 5px 7px;
  border-radius: 10px;
  margin-right: 0.5rem;
  position: relative;
}

/* User Card */
.user-card {
  position: relative;
  width: 380px;
}

.user-card .text-content {
  width: 170px;
}

.user-card .name {
  font-weight: 700;
}

.user-card .text,
.user-card .date {
  font-size: 14px;
  color: rgb(180, 180, 180);
}

.user-online {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(0, 219, 0);
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
}
.user-offline {
  display: inline-block;
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background: rgb(150, 151, 150);
  position: absolute;
  top: -3px;
  right: -3px;
  z-index: 2;
}

/* Chat Box */
.chat-box {
  max-height: calc(100vh - 10rem);
  overflow-y: auto;
  border-radius: 10px;
}

.messages-box {
  height: 85vh;
}

/* Chat Header */
.chat-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.75rem;
  background: rgb(30, 30, 30);
}

/* Messages */
.messages {
  max-height: calc(100vh - 15rem);
  overflow-y: auto;
  padding: 0 2rem;
}

.message {
  background: #282828;
  padding: 0.75rem;
  border-radius: 5px;
  max-width: 50%;
}

.message.self {
  background: #00bd9b;
}

.message-footer {
  font-size: 12px;
  align-self: flex-end;
  font-weight: 400;
}

/* Chat Input  */
.chat-input {
  width: 100%;
  background: rgb(30, 30, 30);
  padding: 1rem;
}
.chat-input2 {
  width: 100%;
  background: #825a3f;
  padding: 1rem;
}
.send-btn {
  border: none;
  background: rgba(72, 112, 223, 1);
  height: 40px;
  width: 40px;
  border-radius: 50%;
}

/* Notification */
.notifications {
  position: relative;
}

.notifications-icon {
  cursor: pointer;
  position: relative;
  margin-right: 1rem;
}

.notifications-box {
  max-height: 50vh;
  width: 300px;
  position: absolute;
  top: 2rem;
  right: 0;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  background: #181d31;
  z-index: 5;
}

.notifications-header {
  padding: 1rem;
  padding-bottom: 0;
  display: flex;
  justify-content: space-between;
}

.notifications-box h3 {
  font-weight: 700;
  font-size: 20px;
}

.mark-as-read {
  cursor: pointer;
  font-weight: 700;
  opacity: 0.8;
}

.notification {
  font-size: 14px;
  margin: 1rem 0;
  padding-bottom: 0.2rem;
  border-bottom: 1px solid rgb(207, 207, 207);
  display: flex;
  flex-direction: column;
  cursor: pointer;
  padding: 0.5rem 1rem;
}

.notification.not-read {
  background: #263159;
}

.notification-time {
  margin-top: 0.2rem;
  font-size: 12px;
  color: #e0e0e0;
}

.notification-count {
  display: flex;
  background: #00bd9b;
  height: 25px;
  width: 25px;
  font-size: 14px;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: -10px;
  right: -15px;
}

.this-user-notifications {
  display: flex;
  background: #00bd9b;
  height: 20px;
  width: 20px;
  font-size: 12px;
  font-weight: 700;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}
.loader {
  border: 4px solid rgba(255, 255, 255, 0.7); /* Adjust transparency */
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 50px; /* Increase size for a more prominent loader */
  height: 50px; /* Increase size for a more prominent loader */
  animation: spin 1s linear infinite, pulsate 1s ease-in-out infinite; /* Add pulsating effect */
  margin: auto;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes pulsate {
  0% {
    transform: scale(0.8);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(0.8);
  }
}
