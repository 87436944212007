/* Apply styles to the default select element */
.custom-select {
  padding-right: 20px; /* Add padding to the right */
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  /* background: white !important; */
}

/* Style the right arrow icon (dropdown arrow) */
.custom-select::-ms-expand {
  display: none; /* Hide the default arrow icon in Internet Explorer */
}

/* Use a custom arrow icon for other browsers */
.custom-select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: url('../../../../assets/svg/dropDown.png') no-repeat right center; /* Replace 'path-to-custom-arrow-icon.svg' with the path to your custom arrow icon */
  background-size: 16px 16px;
  box-sizing: border-box;
  background-position: calc(100% - 16px);
}

.custom-select option:hover {
  background-color: #825a3f; /* Set the background color on hover */
}

.custom-select option {
  font-family: 'PoppinsRegular'; /* Use the Poppins font or change it accordingly */
  font-size: 0.875rem /* 14px */;
  line-height: 1.25rem /* 20px */;
  color: #000; /* Text color */
}
.custom-select option::after {
  color: #000; /* Text color */
}
